import { _get, _post, _put } from "./API";
const baseURL = process.env.REACT_APP_DONATION_ENDPOINT;
const localURL = '/organizers';

export const OrganizerAPI = {

    getActiveOrganisers : async () => {
      return await _get(`${baseURL}/public${localURL}`);
    },
    getAllOrganisers : async () => {
      return await _get(`${baseURL}${localURL}/all`);
    },
    getPendingOrganisers : async () => {
      return await _get(`${baseURL}${localURL}/pending`);
    },
    approveOrganiser : async (id: string) => {
      return await _put(`${baseURL}${localURL}/${id}/approve`);
    },
    getOrganisersById: async(id:any, isPrivate?:boolean) =>{
      if (isPrivate && localStorage.getItem('token')) {
        // let cfg = { headers: {'Authorization' : `Bearer ${localStorage.getItem('token')}` } };
        return await _get(`${baseURL}/profile${localURL}/${id}`);
      } else
      return await _get(`${baseURL}/public${localURL}/${id}`);
    },
    declineOrganiser : async (id: string,data:any) => {
      return await _put(`${baseURL}${localURL}/${id}/decline`,data);
    },
    validateDuplicateContactNumber:  async (contactNumber: any) => {
      return await _get(`${baseURL}/public/organizer/duplicateContactNumber?contactNumber=${contactNumber}`);
    },
    validateDuplicateUserId:  async (id: any) => {
      return await _get(`${baseURL}/public/organizer/duplicateId?id=${id}`);
    },
    validateDuplicateEmail:  async (email: any) => {
      return await _get(`${baseURL}/public/organizer/duplicateEmail?email=${email}`);
    },
    suspendOrganiser : async (id: string,data:any) => {
      return await _put(`${baseURL}${localURL}/${id}/suspend`,data);
    },
    resumeOrganiser : async (id: string) => {
      return await _put(`${baseURL}${localURL}/${id}/resume`);
    },
    searchAnyOrganiserPublic: async (searchStr: any) => {
      return await _get(`${baseURL}/public${localURL}/search?text=${searchStr}`,{hideLoader:true});
    },
    getAllOrganiserDocuments: async () => {
      return await _get(`${baseURL}/profile/organizer/documents`);
    },
    downloadOrganisersCSV: async () => {
      return await _get(`${baseURL}/profile/organizer/details/csv`);
    },
    updateOrganiser: async (id:any,data:any) => {
      return await _put(`${baseURL}/profile/organizer/update?orgId=${id}`,data);
    },
    getLinkedDocCategoriesByOrganiserId:async (orgId:any) => {
      return await _get(`${baseURL}/profile/organizer/platforms/linked-doc-categories?orgId=${orgId}`);
    },
    getPrivateDocCategories: async () => {
      return await _get(`${baseURL}/platforms/doc-categories`);
    }
}