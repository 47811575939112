import { BehaviorSubject } from 'rxjs';
import { Snackbar, Alert, AlertColor  } from '@mui/material';
import { ACTION_CONSTANTS, downloadFile } from '../configs/Utils';
import { ProjectAPI } from './ProjectAPI';
import { DmsAPI } from './DmsAPI';

const navMenuSubject = new BehaviorSubject('');
const userSubject = new BehaviorSubject(false);
const layoutSubject = new BehaviorSubject('');
const loaderSubject = new BehaviorSubject(false);
const statusSubject = new BehaviorSubject<any>({});
const statusSubjectHandler = new BehaviorSubject<any>({});
const contentDispositionHandler = new BehaviorSubject<any>(null);


const MessageService = {
    setNavMenu: (d: string) => {
        navMenuSubject.next(d);
    },
    setUserStatus: (f: boolean) => {
        userSubject.next(f);
    },
    handleUserStatus: (f: boolean) => {
        statusSubjectHandler.next(f);
    },
    setLayout: (d: string) => {
        layoutSubject.next(d);
    },
    setLoading: (f: boolean) => {
        loaderSubject.next(f);
    },
    setStatus: (f: {type: string, text: string, subtext?: string} | null) => {
        statusSubject.next(f);
        setInterval(() => statusSubject.next(null), 6000);
    },
    getJson: async function(filename: string) {
        const res = await fetch(`${process.env.PUBLIC_URL}/jsons/${filename}.json`);
        return await res.json();
    },
    downloadReceipt(txn: any) {
        ProjectAPI.getReceiptDMSCode(txn).then((res: any) => {
            let url = DmsAPI.getDocumentUrl(res);
            downloadFile(url, true);
        })
    },
    setContentDisposition: (res: any) => {
        contentDispositionHandler.next(res);
    }
}



export const getFundraisersByCategories = (organiser: any, programs: any) => {
    let arr: any = [];
    let campaigns = JSON.parse(localStorage.getItem(ACTION_CONSTANTS.PLATFORM) as string).campaigns;
    campaigns.forEach((cat: any) => {
        let funds = programs.filter((f: any) => f.campaignCode === cat.code);
        if (funds.length > 0) {
            let obj = { ...cat, fundraisers: funds };
            arr.push(obj);
        }
    });
    return arr;
}

export const getTotalSuccessfulDonationsFromCostCodes = (ccodes: any) => {
    let total = 0;
    ccodes.forEach((cc: any) => {
        let success = cc.donations.filter((d: any) => d.result === 'SUCCESS');
        total += success.length;
    });
    return total;
}

export const getFundInfo = (program: any): { totalFundRaised: number; projectBudget: number; totalSuccessfulDonations: number } => {
    return { 
        totalFundRaised: program.totalFundRaised | 0, 
        projectBudget: program.projectBudget | 0, 
        totalSuccessfulDonations: program.costCodes ? getTotalSuccessfulDonationsFromCostCodes(program.costCodes) : 0};
}

export const hasDownloadDocPermission = () => {
    let usertype = localStorage.getItem('userType') as string;
    if (usertype === ACTION_CONSTANTS.ORGANISER || usertype === ACTION_CONSTANTS.ADMIN) return true;
    return false;
}


export { MessageService, navMenuSubject, layoutSubject, loaderSubject, statusSubject, userSubject,statusSubjectHandler, contentDispositionHandler };