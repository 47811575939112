import { _get, _post, _put } from "./API";
const baseURL = process.env.REACT_APP_DONATION_ENDPOINT;
const localURL = '/projects';

export const ProjectAPI = {
  createProject: async (data: any) => {
    return await _post(`${baseURL}${localURL}`, data);
  },
  approveProject: async (id: string) => {
    return await _put(`${baseURL}${localURL}/${id}/approve`, null);
  },
  declineProject: async (id: string, data: any) => {
    return await _put(`${baseURL}${localURL}/${id}/decline`, data);
  },
  getProjects: async () => {
    return await _get(`${baseURL}/public${localURL}`);
  },
  getUpcomingProjects: async () => {
    return await _get(`${baseURL}/public/upcoming`);
    // return [];
  },
  getOngoingProjects: async () => {
    return await _get(`${baseURL}/public/current`);
  },
  getClosedProjects: async () => {
    return await _get(`${baseURL}${localURL}/closed`);
  },
  getPendingProjects: async () => {
    return await _get(`${baseURL}${localURL}/pending`);
  },
  getProjectDetailsById: async (id: any) => {
    return await _get(`${baseURL}/public${localURL}/${id}`);
  },
  getAlbumsByProject: async (id: any) => {
    return await _get(`${baseURL}/public${localURL}/${id}/albums`);
  },
  getProjectsByOrganiserId: async (id: any) => {
    return await _get(`${baseURL}${localURL}/organizer?id=${id}`);
  },
  getProjectsByOrganiserIdPublic: async (id: any) => {
    return await _get(`${baseURL}/public${localURL}/organizer?id=${id}`);
  },
  getUrgentRequirementByOrganiserId: async (id: any) => {
    return await _get(`${baseURL}/public${localURL}/urgent?orgId=${id}`);
  },
  addExpenditureOfProjects: async (id: any, data: any) => {
    return await _post(`${baseURL}${localURL}/${id}/expenditure`, data);
  },
  addAlbumsToProject: async (id: any, data: any) => {
    return await _post(`${baseURL}${localURL}/${id}/albums`, data);
  },
  getDonationStats: async () => {
    return await _get(`${baseURL}/stats${localURL}/donations`);
  },
  getDonationsBreakup: async (id: any, projCode: any, costCodes: string) => {
    return await _get(`${baseURL}${localURL}/donation/${id}/${projCode}/costCode?costCode=${costCodes}`);
  },
  getPaymentStatus: async (stream: any, userid: any) => {
    return await _get(`${baseURL}${localURL}/paymentstatus?payload=${stream}&orgId=${userid}`);
  },
  verifyPaymentStatus: async (txn: any) => {
    return await _get(`${baseURL}${localURL}/verifypaymentstatus?txnId=${txn}`);
  },
  getReceiptDMSCode: async (txn: any) => {
    return await _get(`${baseURL}${localURL}/receipt?txnId=${txn}`);
  },
  closeProjects: async(id:any) =>{
    return await _put(`${baseURL}${localURL}/${id}/closed`);
  },
  updateProject: async (id: any, data: any) => {
    return await _put(`${baseURL}${localURL}/update/${id}`, data);
  },
  searchAnyProjectsPublic: async (searchStr: any) => {
    return await _get(`${baseURL}/public${localURL}/search?text=${searchStr}`,{hideLoader:true});
  },
  getAlbumsByProjectIdPublic: async (projectID: any) => {
    return await _get(`${baseURL}/public${localURL}/${projectID}/albums`);
  },
  getTotalDonations: async () => {
    return await _get(`${baseURL}/public${localURL}/totalDonations`);
  }
}