
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './PaymentStatus.scss';
import { ACTION_CONSTANTS, getImagesList } from '../../../configs/Utils';
import styles from '../../../scss/ngo.module.scss';
import { BsCheckCircle } from 'react-icons/bs';
import { useParams } from 'react-router';
import { MessageService, ProjectAPI } from '../../../services';
import { useSearchParams } from 'react-router-dom'
import { FaRegTimesCircle } from 'react-icons/fa';
import { MdDownload } from 'react-icons/md';
import { Logo } from '../Widgets';

export const PaymentStatus = (props: any) => {
    const [paymentStatus, setPaymentStatus] = useState<any | null>(null);
    const styles = JSON.parse(localStorage.getItem('styles') as string);
    const [searchParams, setSearchParams] = useSearchParams();
    const [goBackURL, setNavigationUrl] = useState<any>();
    const navigate = useNavigate();

    const _goback = () => {
        window.location.replace(localStorage.getItem('PostPaymentURL') as string);
        localStorage.removeItem('PostPaymentURL');
        // console.log(useH)
        // window.location.href = `${process.env.PUBLIC_URL}`
    }
    const _getPaymentStatus = () => {
        let payment_stream = searchParams.get('payload');
        let donatedToOrganiser = localStorage.getItem('donatedToOrganiser');
        ProjectAPI.getPaymentStatus(payment_stream, donatedToOrganiser).then((res: any) => {
            if (res) {
                const str = res.split('|');
                const data: any = {};
                for (const pair of str) {
                    const [key, value] = pair.split('=');
                    data[key] = value;
                }
                const jsonResult = JSON.stringify(data, null, 2);
                setPaymentStatus(JSON.parse(jsonResult))
            }
        })
    }
    const _downloadReceipt = (txn: any) => {
        MessageService.downloadReceipt(txn);
    }
    useEffect(() => {
        _getPaymentStatus();
    }, [])
    return (
        <>
            <div className='paddingRL payment_page relative'>
                {
                    <div className='paymentStatusContainer'>
                        <div className="appHeader flex">
                            <div className='flex align-items-center'>
                                <div className='homegovLogo' onClick={() => navigate('..')}><Logo /></div>
                            </div>
                        </div>
                        <div className="custom_form text-center">
                            <div className='iconHeader'>
                                {paymentStatus && paymentStatus.result && paymentStatus.result === "SUCCESS" && <div className={'success'} >
                                    <BsCheckCircle /> <div>SUCCESS</div>
                                </div>
                                }
                                {(paymentStatus && paymentStatus.result != "SUCCESS") && <div className={'error'} >
                                    <FaRegTimesCircle /> <div>FAILED</div>
                                </div>}

                            </div>
                            {paymentStatus &&
                                <div className='paymentInfo'>
                                    <div >For payment of <b>{paymentStatus.currency} {paymentStatus.amount}</b></div>
                                    <div>Transaction Id: <b>{paymentStatus.trackId}</b></div>
                                    <div>Payment Mode : {paymentStatus.paymentMode}</div>
                                    {paymentStatus.result == "SUCCESS" && <div className='downloadReceiptBtn' onClick={() => _downloadReceipt(paymentStatus.trackId)}><MdDownload className='downloadReceipt' /> Receipt</div>}
                                    <div className={styles.primaryBtnStyle + ' paymentButton'} onClick={_goback}>&nbsp;Go to Site</div>

                                </div>
                            }

                        </div>
                    </div>
                }
            </div>
        </>
    );
}