
import axios from 'axios';
import { MessageService } from './helper.service';
import { PublicAuth, AuthAPI } from './AuthAPI';
import { PublicMaster } from './MasterAPI';
import { PublicDonor } from './DonorsAPI';
import { useNavigate } from 'react-router';
import { ACTION_CONSTANTS } from '../configs/Utils';

// const publicAPIs: string[] = ['/platforms', '/schema/json', '/schema/ui', '/signup/organizer', '/auth/signin', '/auth/otp', '/auth/otp/validate', '/organizers']

let config = {
  headers: {
    // 'Content-Type': "application/json, text/plain, */*"
    
  }
};
export const _get = async (url: string,extraData?:any) => {
  let newconfig = {...config};
  if(extraData){
    newconfig={...config,...extraData}
  }
  try {
    const response = await axios.get(url, newconfig);
    return response?.data;
  } catch (error) {
    throw error;
  }
};
export const _post = async (url: string, data: any) => {
  try {
    const response = await axios.post(url, data, config);    
    return response ?.data;
  } catch (error) {
    throw error;
  }
};
export const _put = async (url: string, data?: any) => {
  try {
    const response = await axios.put(url, data, config);
    return response ?.data;
  } catch (error) {
    throw error;
  }
};
export const _delete = async (url: string, data?: any) => {
  try {
    const response = await axios.delete(url, data);
    return response ?.data;
  } catch (error) {
    throw error;
  }
};

export const _getFetch = async (url: string) => {
  const res = await fetch(url, config);
  if (!res.ok) {
    const message = `An error has occured: ${res.status} - ${res.statusText}`;
    throw new Error(message);
  }
  return await res.json();
};

const isPublic = (url: any) => {
  return [...PublicAuth, ...PublicMaster, ...PublicDonor].includes(url) || url.includes('/public');
}
export const _concurrentRequest = async(requestList:any) =>{
  try {
    const response = await axios.all(requestList);
    return response
  } catch (error) {
    throw error;
  }
}

const clearLocalStorage = ()=>{
  localStorage.removeItem('token');
  localStorage.removeItem(ACTION_CONSTANTS.USER);
  localStorage.removeItem(ACTION_CONSTANTS.USERID);
  localStorage.setItem('userType','user');
  localStorage.removeItem('refreshtoken');
}
const refreshToken = async function () {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('refreshtoken')}` },
    body: JSON.stringify({ "uid": localStorage.getItem('userid'), "credential": localStorage.getItem('token') })
  }
  const res = await fetch(`${process.env.REACT_APP_DONATION_ENDPOINT}/auth/refresh`, requestOptions);
  return await res.json();
}



axios.interceptors.request.use(
  config => {
    let tempconfig:any = JSON.parse(JSON.stringify(config))
    if(!tempconfig.hideLoader) MessageService.setLoading(true);
    if (isPublic(config.url as string)) {
      delete config.headers.Authorization;
      return config;
    }
    if (localStorage.getItem('token')) config.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    return config;
  },
  error => {
    console.log(error);
    MessageService.setLoading(false);
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  async (response) => {
    MessageService.setLoading(false);
    if (response.headers['content-disposition']) 
      MessageService.setContentDisposition(response.headers['content-disposition']);
    else MessageService.setContentDisposition(null);
    return response;
  },
  async (error) => {

    if (error.response) {
      if (error.response.status == 401) {
        if(localStorage.getItem('userType') && localStorage.getItem('token')){
          const newAccess = await refreshToken();
          if (newAccess?.token && newAccess?.refresh) {
            localStorage.setItem('token', newAccess.token)
            localStorage.setItem('refreshtoken', newAccess.refresh)
            error.config.headers['Authorization'] = `Bearer ${newAccess.token}`;
            return axios(error.config);
          } else {
            clearLocalStorage();
            MessageService.setStatus({ type: 'error', text: newAccess?.message });
            setTimeout(()=>{
              window.location.href= process.env.PUBLIC_URL;
            })
          }
        } else {
          clearLocalStorage();
        }
      }
      MessageService.setLoading(false);
      MessageService.setStatus({ type: 'error', text: error.response.data.message });
      // return Promise.reject(error);
    } else {
      MessageService.setStatus({ type: 'error', text: 'Unexpected Error' });
    }
  }
);