import React, { Component, lazy, Suspense, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

import { ACTION_CONSTANTS, Loader, LoadingPage, USERTYPE } from './configs/Utils';

import ngostyles from './scss/ngo.module.scss';
import pujostyles from './scss/pujo.module.scss';
import { loaderSubject, statusSubject, statusSubjectHandler } from './services/helper.service';
import { MasterAPI } from './services';
import { PaymentStatus } from './Shared/components/PaymentStatus/PaymentStatus';
import AlertComponent from './Shared/components/Alert/AlertComponent';
import { Footer } from './Shared/components/Widgets';
const AuthRouter = lazy(() => import("./modules/AuthenticationModule/AuthRouter"));
const DonorRouter = lazy(() => import("./modules/DonorModule/DonorRouter"));
const AdminRouter = lazy(() => import("./modules/AdminModule/AdminRouter"));
const OrganisationRouter = lazy(() => import("./modules/OrganisationModule/OrganisationRouter"));
const NotFoundPage = lazy(() => import("./Shared/NotFoundPage"));
const Signup = lazy(() => import("./modules/AuthenticationModule/SignupForm/SignupForm"));
const ProgramCreation = lazy(() => import("./Shared/containers/ProgramCreation/ProgramCreation"));
const baseurl: string = (process.env.REACT_APP_BASEHREF as string)

const App = () => {
  let status_subscription: any;
  let subscription_handler: any;
  const [isLoading, setIsLoading] = useState(false);
  const [statusMsg, setStatusMsg] = useState<any>();
  const [statushanderTrigger, setStatusHandlerTigger] = useState<any>(true);
  // const [opendialog, setDialogflag] = useState<boolean>(false);
  const [styles, setStyles] = useState<any | null>(null);

  let loader = <></>;
  if (isLoading) {
    loader = <Loader />;
  }

  const _getPlatform = () => {
    MasterAPI.getPlatform().then(res => {
      document.title = res.name === 'EDaan' ? 'e-DAAN' : res.name;
      localStorage.setItem('app', res.name);
      let styles = res.name === ACTION_CONSTANTS.EPUJO ? pujostyles : ngostyles;
      localStorage.setItem('styles', JSON.stringify(styles));
      setStyles(styles)
      localStorage.setItem(ACTION_CONSTANTS.PLATFORM, JSON.stringify(res));
      if (!localStorage.getItem('userType')) {
        localStorage.setItem('userType', ACTION_CONSTANTS.USER)
      }
      ACTION_CONSTANTS.USERTYPE = localStorage.getItem('userType')
    }).catch(function (err: any) {
      // setDialogflag(true)
      // {opendialog && statusMsg && <ConfirmationDialogComponent options={{ type: ACTION_CONSTANTS.ERROR,header: err, buttonList: [ACTION_CONSTANTS.CLOSE], action: dialogAction }} />}
    });
  }

  const _handleClose = () => {
    setStatusMsg(null);
  }


  React.useEffect(() => {
    console.log(baseurl);
    _getPlatform();

  }, [])

  React.useEffect(() => {
    let subscription = loaderSubject.subscribe((flag: boolean) => {
      setIsLoading(flag);
    })
    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [])

 
 
  React.useEffect(() => {
    
      status_subscription = statusSubject.subscribe((data: any) => {
        if (statushanderTrigger && data && Object.keys(data).length !== 0) {
          setStatusMsg(data);
        }
      })

    subscription_handler = statusSubjectHandler.subscribe((data: any) => {
      setStatusHandlerTigger(data)
      if (!data && status_subscription) {
        setStatusMsg(null);
      }
    })
  }, [])


  return (
    <>
      {loader}
      {/* {opendialog && <ConfirmationDialogComponent options={{ type: statusMsg.type,header: statusMsg.text, buttonList: [ACTION_CONSTANTS.CLOSE], action: dialogAction }} />} */}
      {statusMsg && <AlertComponent message={statusMsg.text} type={statusMsg.type} />}
      <Suspense fallback={<LoadingPage />}>
        {styles && <Router basename={baseurl}>
          <Routes>
            <Route path='/' Component={AuthRouter}></Route>
            <Route path={`/paymentstatus`} element={<PaymentStatus />}></Route>
            <Route path={`/signup`} element={<Signup />}></Route>
            {/* <Route path={`/${ACTION_CONSTANTS.ORGANISER}-create`} element={<Signup type={ACTION_CONSTANTS.ORGANISER} />}></Route>
            <Route path={`/${ACTION_CONSTANTS.COMMITTEE}-create`} element={<Signup type={ACTION_CONSTANTS.COMMITTEE} />}></Route> */}
            <Route path={`/program-create`} Component={ProgramCreation}></Route>
            <Route path={`/${ACTION_CONSTANTS.PAYMENTMERCHANT}/*`} Component={AdminRouter}></Route>
            <Route path={`/${ACTION_CONSTANTS.ADMIN}/*`} Component={AdminRouter}></Route>
            <Route path={`/${ACTION_CONSTANTS.DONOR}/*`} Component={DonorRouter}></Route>
            <Route path={`/${ACTION_CONSTANTS.USER}/*`} Component={DonorRouter}></Route>
            <Route path={`/${ACTION_CONSTANTS.ORGANISER}/*`} Component={OrganisationRouter}></Route>
            <Route path={`/${ACTION_CONSTANTS.COMMITTEE}/*`} Component={OrganisationRouter}></Route>
            {/* <Route path={`/terms-of-use`} element={<TermsOfUsePage header='terms-of-use' />}></Route>
            <Route path={`/privacy-policy`} element={<TermsOfUsePage header='privacy-policy' />}></Route>
            <Route path={`/disclaimer`} element={<TermsOfUsePage header='disclaimer'  />}></Route> */}
            <Route path='*' element={< NotFoundPage />}></Route>
          </Routes>
        </Router>}
      </Suspense>
      <Footer/>
    </>
  );
}

export default App;