import { Card, CardContent, CardMedia } from '@mui/material';
import { ACTION_CONSTANTS, getImageUrl } from '../../../configs/Utils';
import './HomeCard.scss';
import { useNavigate } from "react-router-dom";
import { FundInfo } from '../Widgets';
import Moment from 'moment';
import { getFundInfo } from '../../../services/helper.service';

const HomeCard = (props: { card: any, cardFor: 'fundraiser' | 'ngo' | undefined, type?: string, hideDetails?: boolean | undefined, onClickHandle?: any | undefined }) => {
    const styles = JSON.parse(localStorage.getItem('styles') as string);

    const navigate = useNavigate()

    const _onClickProgram = (card_name: any) => {
        // navigate(`../${ACTION_CONSTANTS.USERTYPE}/events/${card_name}`);
        navigate(`../${ACTION_CONSTANTS.USERTYPE}/events`);
    }
    const _onClickOrganisation = (id: any) => {
        // navigate(`../${ACTION_CONSTANTS.USERTYPE}/organisers`);
        navigate(`../${ACTION_CONSTANTS.USERTYPE}/organisers/${id}`);
    }

    return (
        <>
            {props.cardFor === 'fundraiser' &&
                <Card className='pujoCard' sx={{ display: 'flex', flexDirection: 'column' }} onClick={() => _onClickProgram(props.card.code)}>
                    <CardMedia component="div" sx={{ pt: '46.25%', position: 'relative', borderRadius: '10px' }} image={props.card.imageCodes?.length > 0 ? getImageUrl(props.card.imageCodes[0]) : getImageUrl()} >
                        <div className={'cardHeader ' + styles.cardHeader} >
                            <div className='cardName'>{props.card.name}</div>
                            <div className='categoryDiv'>
                                <div className="cardCategory">{props.card.campaignName}</div><div className="cardMore">More Info...</div>
                            </div>
                            <div className='fundInfoWrap'><FundInfo data={getFundInfo(props.card)} /></div>
                        </div>
                    </CardMedia>
                </Card>
            }
            {props.cardFor === 'ngo' &&
                <Card className='pujoCard' sx={{ display: 'flex', flexDirection: 'column' }} onClick={() => _onClickOrganisation(props.card.id)}>
                    <CardMedia component="div" sx={{ pt: '46.25%', position: 'relative' }} image={getImageUrl({ code: props.card?.bk_image }, 'background')} >
                        <div className={'cardHeader ' + styles.cardHeader} >
                            <div className='cardName'>{props.card.legal_name}</div>
                            <div className='categoryDiv'>
                                <div className="cardCategory">{props.card.address.city}</div>
                                <div className="cardCategory">More Info...</div>
                            </div>
                        </div>
                    </CardMedia>
                </Card>
            }
        </>
    )
}


export default HomeCard;