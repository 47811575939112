import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import { DialogContent, DialogTitle, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { IPdfViewer, PdfViewer, PdfViewerOption } from 'prm-react-pdf-viewer'
import 'prm-react-pdf-viewer/dist/index.css'
import './ViewModalComponent.scss'
import { DmsAPI } from '../../../services';


export const ViewModalComponent = (props: { options: { header: string, action: any, pdfUrl?: string, type?: string } }) => {

    const [open, setOpen] = useState(true);
    const [docurl, setdocurl] = useState<any>(null)

    const fullScreen = useMediaQuery(useTheme().breakpoints.down('xl'));

    const handleClose = () => {
        setOpen(false);
        props.options.action();
    };

    // const getDocUrl = (): string => {
    //     return props.options.pdfUrl || '';
    // }

    const _getDocUrl = async () => {
        let code = props.options.pdfUrl;
        let ext = props.options.type;
        if (code) {
            let f = await DmsAPI.fetchAuthenticatedFile(code, ext);
            setdocurl(f);
        }
    }

    useEffect(() => {
        if (props.options.pdfUrl) {
            _getDocUrl();
        }
    }, [props.options.pdfUrl])

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            maxWidth='xl'
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                {props.options.header}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                <div className="modalContent" style={{ width: '1000px', height: '80vh' }}>
                    {/* { (!props.options.type || props.options.type === 'pdf') && <PdfViewer options={{docUrl: getDocUrl(), initialZoomPercentage:100}}/> }
                    { props.options.type === 'image' && <img src={getDocUrl()} style={{width:'100%', height: 'fit-content'}} /> } */}
                    {/* <iframe src=""
                      style={{width:'600px', height:'400px'}} ></iframe> */}
                    {
                        docurl &&
                        <>
                            {props.options.type?.includes('pdf') ?
                                <PdfViewer options={{ docUrl: docurl, initialZoomPercentage: 100 }} />
                                :
                                <img src={docurl} style={{ maxWidth: '100%' }} />
                            }
                        </>
                    }
                </div>
            </DialogContent>
        </Dialog>
    );
}
