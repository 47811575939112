import { _get, _post, _put } from "./API";
const baseURL = process.env.REACT_APP_DONATION_ENDPOINT;
export const PublicMaster : string[] = [`${baseURL}/platforms`, `${baseURL}/schema/json`, `${baseURL}/schema/ui`]

export const MasterAPI = {

    getPlatform : async() => {
      return await _get(`${baseURL}/platforms`);
    },
    getFormSchema : async () => {
      return await _get(`${baseURL}/schema/json`);
    },
    getSchemaUI : async () => {
      return await _get(`${baseURL}/schema/ui`);
    },
    getStaticContent: async()=>{
      return await _get(`/jsons/OthersPage.json`);
    }
}