
import { useEffect, useState } from 'react';
import { Card, CardContent } from '@mui/material';
import { CarouselComponent } from '../Carousel/CarouselComponent';
import './ProgramCard.scss';
import { FaHeart } from 'react-icons/fa';
import { MdOutlineDoubleArrow } from "react-icons/md";
import { ACTION_CONSTANTS, canDonate, getImagesList, isProgramUpcoming } from '../../../configs/Utils';
import styles from '../../../scss/ngo.module.scss';
import { useNavigate } from 'react-router-dom';
import { FundInfo } from '../Widgets';
import DonationDialog from '../../Dialogs/DonationDialog/DonationDialog';
import { getFundInfo } from '../../../services/helper.service';

export const ProgramCard = (props: { program: any, size?: 'small' }) => {

    const [opendialog, setDialogflag] = useState<boolean>(false);
    const [program, setProgramDetail] = useState<any>(props.program);
    const usertype = localStorage.getItem('userType');
    const navigate = useNavigate();

    const _getCount = (images: any) => {
        if (images)
            return images.length > 1 ? 2 : 1;
        else return 1;
    }
    const _onDonateClick = () => {
        setDialogflag(true);
    }
    const dialogAction = (action: any, updatedProgram: any) => {
        setDialogflag(false);
        if (updatedProgram) setProgramDetail(updatedProgram);
    }

    const _onClickHandle = (action: any, id: string) => {
        switch(action) {
            case ACTION_CONSTANTS.FUNDRAISER:
                navigate(`../../events/${id}`);
                break;
        }
    }
    useEffect(() => {
        if(props && props.program)
            props.program.isUpcoming = isProgramUpcoming(props.program.startDate)

    }, [])
    return (
        <>
        {opendialog && <DonationDialog program={props.program} dialogAction={dialogAction}/>}
        <Card className='thumbnailCard' sx={{ display: 'flex', flexDirection: 'column' }}>
            <CarouselComponent cards={props.program.imageCodes ? getImagesList(props.program.imageCodes) : getImagesList()} showCount={props.size ? 1 : _getCount(props.program.imageCodes)} entity='thumbnails' autoplay={true} />
            <CardContent sx={{ flexGrow: 1 }}>
                <div className="cardContent">
                    <div className="cardHeader">{props.program.name}</div>
                    <FundInfo data={getFundInfo(props.program)} />
                    <div className="cardCategory">{props.program.campaignName}</div>
                    <div className="cardDescription">{props.program.description}</div>
                </div>
            </CardContent>
            <div className='cardFooter flex'>
                { canDonate(program) && 
                    <div className={styles.primaryBtnStyle + ' footerBtn col flex ' + (props.program.isUpcoming && ' disabledItem')} onClick={() => _onDonateClick()}><FaHeart className={styles.icon}/>Donate Now</div>
                }
                <div className={styles.tertiaryBtnStyle + ' footerBtn col flex'} onClick={() => _onClickHandle(ACTION_CONSTANTS.FUNDRAISER, props.program.code)}>View Details<MdOutlineDoubleArrow className={styles.icon}/></div>
            </div>
        </Card>
        </>
    );
}