import React, { useEffect, useState } from 'react'
import { Footer, Logo } from '../../components/Widgets'
import { ACTION_CONSTANTS, Loader } from '../../../configs/Utils';
import { MasterAPI } from '../../../services';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import { DialogContent, DialogTitle, Divider, IconButton, useMediaQuery, useTheme } from '@mui/material';
import './TermsAndOthersPage.scss';

const TermsAndOthersPage = (props: { header: string, action: any }) => {
    const app = localStorage.getItem('app');
    const [contentData, setcontentData] = useState<any>()
    const [isLoading, setIsLoading] = useState<boolean>(false);

    // const url = window.location.pathname.substring(1)

    useEffect(() => {
        _fetchData()
    }, [])

    const _fetchData = () => {

        let obj = {}
        MasterAPI.getStaticContent().then((res: any) => {
            let data = res.data

            data.map((e: any) => {
                if (e.name === props.header) {
                    obj = e
                }
                setcontentData(obj)
            })
            setIsLoading(false)
        })
    }

    const [open, setOpen] = useState(true);

    const fullScreen = useMediaQuery(useTheme().breakpoints.down('md'));

    const handleClose = () => {
        setOpen(false);
        props.action()
    };

    return (

        // <div className={"backgroundWrap flex paddingRL"}>
        //     <div className="commonHeader flex">
        //         <div className='flex align-items-center'>
        //             <div className='govLogo'><Logo /></div>
        //         </div>
        //     </div>
        //     <div className='flex contentWrap' style={{ margin: '0 -1vw', height: '89vh', overflow: 'auto' }}>
        //         {contentData && contentData}
        //     </div>
        // </div>

        <Dialog
            PaperProps={{ className: 'termsDialogBox' }}
            open={open}
            aria-labelledby="responsive-dialog-title"
        >
            {isLoading && <Loader />}
            <div className='dialog-header flex justify-content-between'>
                {props.header}
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </div>
            <div className='divider' />
            <div className='dialog-body flex'>
                {contentData &&
                    <>
                        {contentData.paragraphs && <Paragraph content={contentData.paragraphs} />}
                        {contentData.bullets && <Bullets content={contentData.bullets} />}
                        {contentData.meta && <Meta content={contentData.meta} />}
                    </>
                }
            </div>
            {/* </DialogContent> */}
        </Dialog>
    )
}

export default TermsAndOthersPage


const Paragraph = (content: any) => {
    return (
        <>
            {content.content.map((e: any) =>
                <p style={{margin: '5px 0'}}>{e}</p>
            )}
        </>
    )
}

const Meta = (content: any) => {
    return (
        <div className='meta-part'>
            {content.content.map((e: any, i: number) =>
                <>
                    {e.name && <div className='meta-name'>{e.order && <span>{i + 1}.&nbsp;</span>}{e.name}</div>}
                    {e.bullets && <Bullets content={e.bullets} type='order' />}
                    {e.paragraphs && <Paragraph content={e.paragraphs} />}
                    {e.meta && <Meta content={e.meta} />}
                </>
            )}
        </div>
    )
}


const Bullets = (content: any) => {
    return (
        <div className='bullets'>
            {content.content.map((e: any) =>
                <li>{e}</li>
            )}
        </div>
    )
}