import { _get, _post, _delete } from "./API";
const baseURL = process.env.REACT_APP_DMS_ENDPOINT;
const backendURL = process.env.REACT_APP_DONATION_ENDPOINT;

const contentTypeMap: any = {
    'pdf': 'application/pdf',
    'png': 'image/png',
    'jpeg': 'image/jpeg',
    'jpg': 'image/jpeg',
    'jfif': 'image/jpeg',
    'gif': 'image/gif',
    'csv': 'text/csv'
}

export const DmsAPI = {

    upload: async function(files: any) {
        var formData = new FormData();
        let meta: any = [];
        files.forEach((fileObj: any) => {
            if (fileObj && fileObj.type) meta.push({'doctype': fileObj.type.code, 'docname': fileObj.file.name});
            formData.append('files',fileObj.file);
        });
        if (meta.length>0) formData.append('meta', JSON.stringify(meta));
        // let filesToSend: [] = files.map((f: any) => f.file);
        // filesToSend.forEach(file => {
        //     formData.append('files',file);
        // })
        return await _post(`${backendURL}/dms/fileupload`, formData);
    },
    // getDocumentUrl: function(dmscode: string) {
    //     let token = localStorage.getItem('token')
    //     let encodedToken = token ? btoa(token) : null;
    //     return encodedToken ? `${backendURL}/dms/download/${dmscode}?base64EncStr=${encodedToken}` : `${backendURL}/dms/download/${dmscode}`;
    // },
    // getPublicDocumentUrl: function(dmscode: string) {
    //     return `${backendURL}/dms/download/public/${dmscode}`;
    // }
    getDocumentUrl: function(dmscode: string){
        return `${backendURL}/dms/download/${dmscode}`;
    },
    deleteDocument: async function(dmscode: string) {
        return await _delete(`${backendURL}/projects/delete-image?fileCode=${dmscode}`);
    },
    fetchAuthenticatedFile: async (code: string, ext?: string) => {
        const res = await _get(code, { responseType: 'blob' });
        const blob = await new Blob([res], { type: contentTypeMap[ext?.replace('.', '') as string] });
        return URL.createObjectURL(blob);
    },
}